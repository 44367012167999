@media screen and (max-width: 400px) {
  #features {
    width: 100%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
}

.btn-zayavka {
  margin: auto;
  font-size: 22;
  border-radius: 10px;
  border: none;
  background: #ff7f00;
  color: white;
  min-height: 50px;
  position: absolute;
  bottom: 10%;
  left: 0%;
  right: 0%;
  width: 80%;
}

.btn-zayavka a {
  color: white;
}
.events {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  flex-direction: column;
  justify-content: center;
}
.events img{
  width: 80%;
  margin: 0 auto;
  border: 1px solid black;
  border-radius: 10px;
}
.events button {
  width: 300px;
  font-size: 22;
  border-radius: 10px;
  border: none;
  height: 50px;
  background: #ff7f00;
  color: white;
  margin: 50 auto;
}
.events button a {
 color: white 
}

.age-groups-request {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stages-training {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
  gap: 50px;
}

.group-s {
  margin-right: 0!important;
}

.group-m {
  margin-right: 0!important;
}

@media (max-width: 480px) { 

  .stages-training {
    flex-direction: column;
  }

  .intro h1 {
    font-size: 63px;
  }

}